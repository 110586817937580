import React from 'react'
import { Stack, Link, IconButton } from '@chakra-ui/react'
import { FaDiscord, FaTwitter, FaInstagram, FaTelegram } from 'react-icons/fa';
import { GiSailboat } from 'react-icons/gi'

function MenuIcon({ name, icon, to, ...rest }) {
    return (
        <>
            <Link href={to} target="_blank" rel="noopener noreferrer" >
                <IconButton
                    aria-label={name}
                    icon={icon}
                    color="white"
                    bgColor="transparent"
                    _focus={{ outline: 0 }}
                    _hover={{ bgColor: "rgb(220, 220, 220, 0.2)" }}
                    fontSize='32px'
                    w="64px"
                    h="64px"
                    isRound
                    {...rest}
                />
            </Link>
        </>
    )
}

function NavIcons() {
    return (
        <>
            <Stack spacing={7} direction={"row"} pt={[17, 17]} align="center">
                {/* <MenuIcon name="Telegram" to="" icon={<FaTelegram />} /> */}
                <MenuIcon name="Twitter" to="https://twitter.com/hello_pooch" icon={<FaTwitter />} />
                <MenuIcon name="Telegram" to="https://t.me/hellopooch" icon={<FaTelegram />} />              
            </Stack>
        </>
    )
}

export default NavIcons