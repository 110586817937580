import React from 'react'
import { Box, Heading, Text, VStack, Stack, HStack, Badge } from '@chakra-ui/react'
import { SettingsIcon, CalendarIcon, TimeIcon, StarIcon, CheckCircleIcon, TriangleUpIcon, ArrowForwardIcon } from '@chakra-ui/icons'

function RoadmapItem({ n, children, icon, ...rest }) {
    return (
        <>
            <HStack w={["95%", "90%", "85%", "65%"]} spacing={8}>
                <Box>
                    {icon}
                </Box>
                <Box w="100%" rounded="12px" borderWidth="2px" borderColor="white" p={8} bgColor="rgba(0, 0, 0, 0.5)">
                    <Stack direction={["column", "column", "column", "column"]} spacing={8}>
                        <Heading align='center' color="#1cd1bc" transform="rotate(-0deg)" mx={0} {...rest}>{n}</Heading>
                        <VStack spacing={8} align="left">
                            {children}
                        </VStack>
                    </Stack>
                </Box>
            </HStack>
        </>
    )
}

function Roadmap() {
    return (
        <>
            <Box my={64} id="Roadmap">
                <VStack align="center" spacing={4}>
                    <Box>
                        <Heading align="center">Our</Heading><Heading color="#1cd1bc" align="center" pb={8} size="4xl">Roadmap</Heading>
                    </Box>

                    <RoadmapItem n='Phase 1' icon={<SettingsIcon color="white" fontSize="32px" />}>
                        {/*<Text fontSize="lg" fontStyle="italic"><Badge colorScheme='purple' p={2} px={4} rounded={10}>0 - 200 Mints</Badge></Text>*/}
                        <Text>• Official announcement of the collection to Doginals community </Text>
                        <Text>• Launch website and start community engagement</Text>
                    </RoadmapItem>

                    <RoadmapItem n="Phase 2" icon={<CalendarIcon color="white" fontSize="32px" />}>
                        {/*<Text fontSize="lg" fontStyle="italic"><Badge colorScheme='purple' p={2} px={4} rounded={10}>201 - 700 <ArrowForwardIcon /> Free Mint</Badge></Text>*/}
                        <Text>• Go Live on minting.</Text>
                        <Text>• $DOGE & Hello Pooch giveaway to the community at 25%, 50%, 70% and 100% minting</Text>
                        </RoadmapItem>

                    <RoadmapItem n="Phase 3" icon={<TimeIcon color="white" fontSize="32px" />}>
                        {/*<Text fontSize="lg" fontStyle="italic"><Badge colorScheme='purple' p={2} px={4} rounded={10}>701 - 1200 <ArrowForwardIcon /> 10 matic</Badge></Text>*/}
                        <Text>• Release of Rarity Ranking App</Text>
                        
                    </RoadmapItem>

                    <RoadmapItem n="Phase 4" icon={<StarIcon color="white" fontSize="32px" />} >
                        {/*<Text fontSize="lg" fontStyle="italic"><Badge colorScheme='purple' p={2} px={4} rounded={10}>1201 - 2500 <ArrowForwardIcon /> 15 matic</Badge></Text>*/}

                        <Text>•  Launch DRC-20 coing exclusively for the Hello Pooch Holders </Text>
                        <Text>•  Season 2 of Hello Pooch exclusively for the holders</Text>
                        <Text>•  Roadmap 2.0 - Focus on building tools for Dogemap</Text>
                    </RoadmapItem>

                </VStack>
            </Box>
        </>
    )
}

export default Roadmap