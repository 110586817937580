import React from 'react'
import { Box, VStack, Image, Heading, SimpleGrid, Text, Center } from '@chakra-ui/react'


import a from '../images/team/1.png'
import b from '../images/team/2.png'
import c from '../images/team/3.png'
import d from '../images/team/4.png'



function TeamMember({ src, name, role }) {
    return (
        <>
            <Box>
                <VStack spacing={8}>
                    <Image rounded="20%" src={src} />
                    <Box align="center">
                        <Text textTransform="uppercase" color="accent">{name}</Text>
                        <Text textTransform="capitalize">{role}</Text>
                    </Box>
                </VStack>
            </Box>
        </>
    )
}

function Team() {
    return (
        <Box pb={32}>
            <Box>
                <Heading align="center">Our</Heading><Heading color="#1cd1bc" align="center" pb={12} size="4xl">Team</Heading>
            </Box>
            <Box mx={[32, 32, 16, 16, 32]} id="Team">
                <Center>
                    <SimpleGrid spacing="6vw" columns={["1", "2", "3"]} rowGap={8} >
                        <TeamMember name="Dan" role="Founder &amp; Artist" src={a} />
                        <TeamMember name="Alicia" role="Head of Marketing and Brand building" src={b} />
                        <TeamMember name="Ron" role="Artist & Web3 Developer" src={d} />                        
                    </SimpleGrid>
                </Center>
            </Box>

        </Box>
    )
}

export default Team