import React from 'react'
import { Box, Stack, Image, VStack, Heading, Text, Spacer } from '@chakra-ui/react'
import a from '../images/samples/10.png'

function Story() {
    return (
        <>
            <Box mx={[4, 4, 16, 32]} my={64} id="Story">
                <Box>
                    <Heading align="center">About</Heading><Heading color="#1cd1bc" align="center" pb={8} size="4xl">US</Heading>
                </Box>
                <Stack
                    direction={["column", "column", "column", "column", "row"]}
                    spacing={12}
                    align="center"
                    justify='space-between'
                >
                    <VStack spacing={12} w="100%">
                        <Text>
                        Hello Pooch is a collection of cute dogs coming to Doginals with the only intention of spreading love and bringing peace of mind to its holders. The more you see these pooch the better you feel relaxed and happy. The pooch is designed with holders in mind with each traits relatable to the real life characters. The art is unique and pleasing making it one of a kind collection on Doginals.
                        </Text>
                        <Text>
                        Hello Pooch is a pure PFP art, with each pooch completely unique and different from any other art on the Doge coin blockchain. 
                        </Text>
                        <Text>
                        As a team, we are very professional and forward thinking while creating this collection. We are one of the few who come with rarity dapp right at the time of launching the collection.
                        </Text>
                        </VStack>
                    <Spacer />
                    <Image src={a} w={["70%", "60%", "45%", "45%", "25%"]} rounded="16px" mx={16} />
                </Stack>
            </Box>
        </>
    )
}

export default Story