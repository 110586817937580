import React from 'react'
import Nav from './components/Nav'
import Home from './components/Home'
import Story from './components/Story';
import Roadmap from './components/Roadmap';
import Welcome from './components/Welcome';
import Team from './components/Team';
import Footer from './components/Footer'
import FAQ from './components/FAQ'
import SeenOn from './components/SeenOn'
import bg from './images/body-bg.png'

import Carousel from './components/Carousel';

import { Box, Center } from '@chakra-ui/react';



function App() {
  return (
    <>
      <Box bgImage={bg} bgAttachment={["fixed"]} background-size ={["cover"]}  background-position ={["Center"]}  width = {["100%"]} height = {["100%"]}>
        <Home>
          <Nav />
        </Home>
        <Welcome />
        <Carousel />
        <Story />
        <Roadmap />
        <Team />
        {/*<SeenOn />*/}
        {/* <FAQ /> */}
        <Footer />
      </Box>
    </>
  );
}

export default App;
